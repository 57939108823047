// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-grabaciones-js": () => import("./../../../src/pages/grabaciones.js" /* webpackChunkName: "component---src-pages-grabaciones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscripcion-js": () => import("./../../../src/pages/Inscripcion.js" /* webpackChunkName: "component---src-pages-inscripcion-js" */),
  "component---src-pages-organizacion-js": () => import("./../../../src/pages/Organizacion.js" /* webpackChunkName: "component---src-pages-organizacion-js" */),
  "component---src-pages-participantes-js": () => import("./../../../src/pages/Participantes.js" /* webpackChunkName: "component---src-pages-participantes-js" */),
  "component---src-pages-programa-js": () => import("./../../../src/pages/Programa.js" /* webpackChunkName: "component---src-pages-programa-js" */)
}

